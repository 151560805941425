import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LinklonPage = () => {
  return (
    <Layout>
      <SEO title="Kevin Heil -  Code, Design & Moderation" />
      <section>
        <article className="article">
          <h2>Allet wichtige im Überblick?</h2>
          <h1>Linklon</h1>
          <ol class="linklon">
            <li>
              <a href="mailto:moin@kevinheil.de" className="button">
              <span role="img" aria-label="Briefumschlag">✉️</span> Booking Anfragen <span role="img" aria-label="Briefumschlag">✉️</span>
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/user/1133997566/playlist/6mhEFsA2CO73tAbXfuqhNz?si=2d3nGyqKQfKB-d9CvokThA"
                className="button"
              >
                Playlist zur aktuellen Sendung
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/playlist/27lUKxl5jsQb62OAmbU4rc?si=1_GuYK37QaapEqB2Frhfdw"
                className="button"
              >
                Playlist Musikarchiv zur Radiosendung
              </a>
            </li>
            <li>
              <a
                href="https://www.radiobremen.de/bremennext/programm-schema/punchline100.html"
                className="button"
              >
                Aktuelle Sendung zum nachhören
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/pg/diesereinekevin/videos/"
                className="button"
              >
                Videos: Studiogäste (u.a. Tua, Jace, DJ Stylewarz, Fynn Kliemann
                uvm.)
              </a>
            </li>
            <li>
              <a href="https://halbwissen.co/" className="button">
                Podcast: Gefährliches Halbwissen
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=05m_F9zLvM8"
                className="button"
              >
                Reportage: Hardcore Booster
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=-GlX1GVSvf4"
                className="button"
              >
                Interview: Disarstar
              </a>
            </li>
            <li>
              <a href="https://youtu.be/W7sG6ZvGv10" className="button">
                Interview: Dendemann
              </a>
            </li>
            <li>
              <a href="https://youtu.be/gwoFMWYFOkc" className="button">
                Interview: Marvin Game
              </a>
            </li>
            <li>
              <a href="https://youtu.be/d4BlsCwZRLc" className="button">
                Interview: Shiml
              </a>
            </li>
          </ol>
        </article>
      </section>
    </Layout>
  )
}

export default LinklonPage
